export const skillsList = [
    {
        id: 0,
        key: 0,
        title: 'HTML',
        description: 'LANGUAGE',
        backgroundColour: 'orange',
        url: '/logos/html.png',
        websiteUrl : 'https://en.wikipedia.org/wiki/HTML',
        rgb: [0.94510, 0.39608, 0.16078],
        hex: '#f16529'
    },
    {
        id: 1,
        key: 1,
        title: 'CSS',
        description: 'LANGUAGE',
        backgroundColour: 'blue',
        url: '/logos/css.png',
        websiteUrl : 'https://en.wikipedia.org/wiki/css',
        rgb: [0.20392, 0.60784, 0.83529],
        hex: '#349bd5'
    },      
    {
        id: 2,
        key: 2,
        title: 'JAVASCRIPT',
        description: 'LANGUAGE',
        backgroundColour: 'YELLOW',
        url: '/logos/javascript.png',
        websiteUrl: 'https://en.wikipedia.org/wiki/JavaScript',
        rgb: [0.93725, 0.85882, 0.31373],
        hex: '#efdb50'
    },
    {
        id: 3,
        key: 3,
        title: 'TYPESCRIPT',
        description: 'LANGUAGE',
        backgroundColour: 'Blue',
        url: '/logos/typescript.png',
        websiteUrl: 'https://www.typescriptlang.org/',
        rgb: [0.18824, 0.47059, 0.77647],
        hex: '#3078c6'
    },
    {
        id: 4,
        key: 4,
        title: 'THREE.JS',
        description: 'LIBRARY',
        backgroundColour: 'orange',
        url: '/logos/threejs.png',
        websiteUrl: 'https://threejs.org/',
        rgb: [1.0, 0.5, 0.0],
        hex: '#ff8000'
    },
    {
        id: 5,
        key: 5,
        title: 'REACT',
        description: 'FRAMEWORK',
        backgroundColour: 'skyblue',
        url: '/logos/react.png',
        websiteUrl: 'https://react.dev/',     
        rgb: [0.38824, 0.85882, 0.98824],
        hex: '#63dbfc'
    },
    {
        id: 6,
        key: 6,
        title: 'R3F',
        description: 'FRAMEWORK',
        backgroundColour: 'skyblue',
        url: '/logos/reactthree.png',
        websiteUrl: 'https://docs.pmnd.rs/react-three-fiber',
        rgb: [0.38824, 0.85882, 0.98824],
        hex: '#63dbfc' 
    },
    {
        id: 7,
        key: 7,
        title: 'GIT',
        description: 'VERSION CONTROL',
        backgroundColour: 'orange',
        url: '/logos/git.png',
        websiteUrl: "https://en.wikipedia.org/wiki/Git",
        rgb: [0.94118, 0.32157, 0.20000],
        hex: '#f05233'
    },
    {
        id: 8,
        key: 8,
        title: 'WEBPACK',
        description: 'PACKAGING',
        backgroundColour: 'skyblue',
        url: '/logos/webpack.png',
        websiteUrl: 'https://webpack.js.org/',
        rgb: [0.19216, 0.65882, 1.00000],
        hex: '#31a8ff'
    },
    {
        id: 9,
        key: 9,
        title: 'VITE',
        description: 'PACKAGING',
        backgroundColour: 'skyblue',
        url: '/logos/vite.png',
        websiteUrl: 'https://vitejs.dev/',
        rgb: [0.56471, 0.43137, 0.99216],
        hex: '#906efd'
    },
    {
        id: 10,
        key: 10,
        title: 'PARCEL',
        description: 'PACKGING',
        backgroundColour: 'purple',
        url: '/logos/parcel.png',
        websiteUrl: 'https://parceljs.org/',
        rgb: [0.19216, 0.65882, 1.00000],
        hex: '#31a8ff' 
    },
    {
        id: 11,
        key: 11,
        title: 'ZAPPAR\nSTUDIO',
        description: 'CREATIVE TOOL',
        backgroundColour: 'skyblue',
        url: '/logos/zappar.png',
        websiteUrl: 'https://www.youtube.com/embed/EiaoA_SWxY8?si=XTDtrPBmso3e4xkS',
        rgb: [0.80392, 0.22353, 0.15294],
        hex: '#cd3927'
    },
    {
        id: 12,
        key: 12,
        title: 'UNITY',
        description: 'CREATIVE TOOL',
        backgroundColour: 'grey',
        url: '/logos/unity.png',
        websiteUrl: 'https://unity.com/games',
        rgb: [0.50196, 0.50196, 0.50196],
        hex: '#808080'
    },
    {
        id: 13,
        key: 13,
        title: 'MAYA',
        description: 'CREATIVE 3D TOOL',
        backgroundColour: 'skyBlue',
        url: '/logos/maya.png',
        websiteUrl: 'https://www.youtube.com/embed/TCkOS4F51MI?si=EVLa4kFjsk-37cRr',  
        rgb: [0.21961, 0.64706, 0.80000],
        hex: '#38a5cc'
    },
    {
        id: 14,
        key: 14,
        title: 'BLENDER',
        description: 'CREATIVE 3D TOOL',
        backgroundColour: 'orange',
        url: '/logos/blender.png',
        websiteUrl: 'https://www.youtube.com/embed/34FBeC9mktE?si=GjpwIm5GbIYR7ZVf', 
        rgb: [0.91765, 0.46275, 0.00392],
        hex: '#ea7601'
    },
    {
        id: 15,
        key: 15,
        title: 'PHOTOSHOP',
        description: 'CREATIVE DESIGN TOOL',
        backgroundColour: 'blue',
        url: '/logos/photoshop.png',
        websiteUrl: 'https://en.wikipedia.org/wiki/Adobe_Photoshop',
        rgb: [0.19216, 0.65490, 1.00000],
        hex: '#31a7ff'
    },
    {
        id: 16,
        key: 16,
        title: 'ILLUSTATOR',
        description: 'CREATIVE DESIGN TOOL',
        backgroundColour: 'orange',
        url: '/logos/illustrator.png',
        websiteUrl: 'https://en.wikipedia.org/wiki/Adobe_Illustrator',
        rgb: [1.00000, 0.60392, 0.00392],
        hex: '#ff9a01'
    },
    {
        id: 17,
        key: 17,
        title: 'VISUAL STUDIO CODE',
        description: 'DEVELOPEMENT ENVIROMENT',
        backgroundColour: 'blue',
        url: '/logos/visualStudio.png',
        websiteUrl: 'https://en.wikipedia.org/wiki/Visual_Studio_Code',
        rgb: [0.19216, 0.65490, 1.00000],
        hex: '#31a7ff'
    },
    {
        id: 18,
        key: 18,
        title: 'HTML',
        description: 'LANGUAGE',
        backgroundColour: 'orange',
        url: '/logos/html.png',
        websiteUrl : 'https://en.wikipedia.org/wiki/HTML',
        rgb: [0.94510, 0.39608, 0.16078],
        hex: '#f16529'
    }
]